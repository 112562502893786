<template>
  <div class="page">
    <div class="profile-board">
      <div class="row">
        <div class="col d-flex align-center">
          <h1 class="font-lg ml-5 mb-5">Поддержка</h1>
        </div>
      </div>

      <v-divider></v-divider>

      <div class="row ma-2">
        <div class="col d-flex align-center">
            <img class="avatar mr-10" src="@/assets/images/social/support-avatar.svg" />
            <div class="d-flex flex-column">
              <div class="mb-3">
                <b>Ваши контакты по техническим вопросам</b>
              </div>
              <div class="d-flex">
                  <a href='https://t.me/sostaffio_tech_bot' target="_blank" class="social-icon mr-7">
                    <img src="@/assets/images/social/tg.svg" /><br>
                    <div class="text-dark-gray">@sostaffio_tech_bot</div>
                  </a>
                  <a href='mailto:support@sostaff.io' class="social-icon">
                    <img src="@/assets/images/social/new-mail.svg" /><br>
                    <div class="text-dark-gray">support@sostaff.io</div>
                  </a>
              </div>

              <!-- <div class="mb-3">
                  E-mail: <a href="mailto:info@teamline-consult.ru">info@teamline-consult.ru</a>
              </div>
              <div class="mb-5">
                  Телефон: 7 925 100 0834
              </div>
              <div>
                <a v-for="item in links" :key="item.href" :href="item.href" target="_blank">
                    <img class="social-icon mr-5" :src="require(`@/assets/images/social/${item.img}`)" />
                </a>
              </div> -->
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Поддержка',
  },
  data() {
    return {
      links: [
        { img: 'tg2.png', href: 'https://t.me/tatyanavavilova' },
        { img: 'mail.png', href: 'mailto:info@teamline-consult.ru' },
        { img: 'wa2.png', href: 'https://wa.me/79251000834' },
        { img: 'tel.png', href: 'tel:+79251000834' },
      ],
    };
  },
};
</script>

<style lang="scss">

.avatar {
    border-radius: 50%;
    width: 100px;
}

.social-icon {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-decoration: none !important;
  img {
    display: block;
    width: 32px;
    height: 32px;
    margin-bottom: 8px;;
  }
}

</style>
